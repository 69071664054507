<template>
  <DashboardAdminTemplate>
    <div class="pd-x-5 pd-b-3">
      <div class="is-flex ai-center">
        <h2 class="mg-r-5">
          {{ type == "answer" ? "จัดการเกณฑ์การประเมิน" : "จัดการคำแนะนำ" }}
        </h2>
        <div>
          <el-button
            v-if="user.role == 99"
            @click="$router.push(`/manage/answer?status=create&type=${type}`)"
            type="primary"
            >{{
              type == "answer" ? "เพิ่มเกณฑ์การประเมิน" : "เพิ่มคำแนะนำ"
            }}</el-button
          >
        </div>
      </div>
      <div class="mg-b-5 pd-5 pd-l-0 pd-t-0">
        <!-- <el-select
          v-model="value"
          placeholder="เลือกขนาดของธุรกิจ"
          class="pd-r-5"
        >
          <el-option
            v-for="item in assessmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <el-row>
          <el-col :span="17">
            <el-form label-position="top" class="is-flex">
              <el-form-item label="ด้านประเมินความพร้อม" class="padding-less">
                <el-select
                  v-model="filter.category"
                  clearable
                  placeholder="เลือกด้านประเมินความพร้อม"
                  class="pd-r-5"
                >
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="ปัจจัยประเมินความพร้อม" class="padding-less">
                <el-select
                  v-model="filter.question"
                  clearable
                  filterable
                  placeholder="เลือกปัจจัยประเมินความพร้อม"
                  class="pd-r-5"
                >
                  <el-option
                    v-for="item in questionList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="3" :push="4" class="text-right">
            <div v-if="type == 'answer'">
              <el-button class="mg-t-1" type="primary" @click="exportAnswer()"
                >Export การประเมิน</el-button
              >
            </div>

            <div v-if="type == 'suggestion'">
              <el-button
                class="mg-t-1"
                type="primary"
                @click="exportRecommend()"
                >Export คำแนะนำ</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <el-tabs
        type="border-card"
        style="width: calc(100% - 5px)"
        v-model="activeName"
      >
        <el-tab-pane label="ธุรกิจขนาดเล็ก (S)" name="s">
          <el-table :data="resultDataSizeS" border v-loading="vLoading">
            <el-table-column
              prop="cateName"
              width="400"
              label="ด้านประเมินความพร้อม"
            >
            </el-table-column>
            <el-table-column prop="questionName" label="ปัจจัยประเมินความพร้อม">
            </el-table-column>
            <!-- <el-table-column prop="address" label="อัปเดตล่าสุด">
            </el-table-column> -->
            <el-table-column width="100" align="center">
              <template slot-scope="scope">
                <!-- <span class="mg-x-7">
                  <i class="fas fa-eye"></i>
                </span> -->
                <span
                  @click="
                    $router.push(
                      `/manage/answer?status=edit&type=${type}&assessment=${scope.row.assessmentId}&cate=${scope.row.cateId}&question=${scope.row.questionId}&filercate=${filter.category}&filerquest=${filter.question}&size=${activeName}`
                    )
                  "
                  class="mg-x-7 cr-pointer hover-primary"
                >
                  <i class="fas fa-pen font-14"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="ธุรกิจขนาดกลาง (M)" name="m"
          ><el-table :data="resultDataSizeM" border v-loading="vLoading">
            <el-table-column
              prop="cateName"
              width="400"
              label="ด้านประเมินความพร้อม"
            >
            </el-table-column>
            <el-table-column prop="questionName" label="ปัจจัยประเมินความพร้อม">
            </el-table-column>
            <!-- <el-table-column prop="address" label="อัปเดตล่าสุด">
            </el-table-column> -->
            <el-table-column width="100" align="center">
              <template slot-scope="scope">
                <!-- <span class="mg-x-7">
                  <i class="fas fa-eye"></i>
                </span> -->
                <span
                  @click="
                    $router.push(
                      `/manage/answer?status=edit&type=${type}&assessment=${scope.row.assessmentId}&cate=${scope.row.cateId}&question=${scope.row.questionId}&filercate=${filter.category}&filerquest=${filter.question}&size=${activeName}`
                    )
                  "
                  class="mg-x-7 cr-pointer hover-primary"
                >
                  <i class="fas fa-pen font-14"></i>
                </span>
              </template>
            </el-table-column> </el-table
        ></el-tab-pane>
        <el-tab-pane label="ธุรกิจขนาดใหญ่ (L)" name="l"
          ><el-table :data="resultDataSizeL" border v-loading="vLoading">
            <el-table-column
              prop="cateName"
              width="400"
              label="ด้านประเมินความพร้อม"
            >
            </el-table-column>
            <el-table-column prop="questionName" label="ปัจจัยประเมินความพร้อม">
            </el-table-column>
            <!-- <el-table-column prop="address" label="อัปเดตล่าสุด">
            </el-table-column> -->
            <el-table-column width="100" align="center">
              <template slot-scope="scope">
                <!-- <span class="mg-x-7">
                  <i class="fas fa-eye"></i>
                </span> -->
                <span
                  @click="
                    $router.push(
                      `/manage/answer?status=edit&type=${type}&assessment=${scope.row.assessmentId}&cate=${scope.row.cateId}&question=${scope.row.questionId}&filercate=${filter.category}&filerquest=${filter.question}&size=${activeName}`
                    )
                  "
                  class="mg-x-7 cr-pointer hover-primary"
                >
                  <i class="fas fa-pen font-14"></i>
                </span>
              </template>
            </el-table-column> </el-table
        ></el-tab-pane>
      </el-tabs>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import { HTTP } from "@/service/axios";
import { HTTPA } from "@/service/axios";

export default {
  components: {
    DashboardAdminTemplate
  },
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  computed: {
    assessmentList() {
      return this.$store.state.assessmentList;
    },
    categoryList() {
      return this.$store.state.categoryList;
    },
    user() {
      return this.$store.state.user;
    },
    questionList() {
      return this.$store.state.questionList;
    },
    token() {
      return this.$store.state.token;
    },
    resultDataSizeS() {
      let filterCate = this.resultData.filter(
        filter =>
          filter.assessmentId == 1 &&
          (this.filter.category == "" ||
            filter.cateId == this.filter.category) &&
          (this.filter.question == "" ||
            filter.questionId == this.filter.question)
      );
      let newData = [];
      filterCate.map(data => {
        newData.push({ id: data.questionId, name: data.questionName });
      });
      this.$store.commit("SET_QUESTION_LIST", newData);
      return this.resultData.filter(
        filter =>
          filter.assessmentId == 1 &&
          (this.filter.category == "" ||
            filter.cateId == this.filter.category) &&
          (this.filter.question == "" ||
            filter.questionId == this.filter.question)
      );
    },
    resultDataSizeM() {
      return this.resultData.filter(
        filter =>
          filter.assessmentId == 2 &&
          (this.filter.category == "" ||
            filter.cateId == this.filter.category) &&
          (this.filter.question == "" ||
            filter.questionId == this.filter.question)
      );
    },
    resultDataSizeL() {
      return this.resultData.filter(
        filter =>
          filter.assessmentId == 3 &&
          (this.filter.category == "" ||
            filter.cateId == this.filter.category) &&
          (this.filter.question == "" ||
            filter.questionId == this.filter.question)
      );
    }
  },
  watch: {
    routeParams() {
      this.setParams();
      this.fetchAnswer();
    }
  },
  data() {
    return {
      activeName: "s",
      vLoading: false,
      type: "",
      resultData: [],
      filter: {
        category: "",
        question: ""
      }
    };
  },
  mounted() {
    this.setParams();
    let obj = {
      token: this.token
    };
    this.getAssessment();
    this.getCategory();
    this.getQuestion();
    this.fetchAnswer();
  },
  methods: {
    getAssessment ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`assessment`)
        .then(res => {
          if (res.data.success) {
            this.$store.commit("SET_ASSESSMENT_LIST", res.data.obj);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_ASSESSMENT_LIST error", e.response);
        });
    },
    getCategory ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`category`)
        .then(res => {
          if (res.data.success) {
            this.$store.commit("SET_CATEGORY_LIST", res.data.obj);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_CATEGORY_LIST error", e.response);
        });
    },
    getQuestion ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`question`)
        .then(res => {
          if (res.data.success) {
            this.$store.commit("SET_QUESTION_LIST", res.data.obj);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_QUESTION_LIST error", e.response);
        });
    },
    setParams() {
      if (this.routeParams != null) {
        if (typeof this.routeParams.type != "undefined") {
          this.type = this.routeParams.type;
        }
        if (typeof this.routeParams.filercate != "undefined") {
          this.filter.category = Number(this.routeParams.filercate);
        } else {
          this.filter.category = "";
        }
        if (typeof this.routeParams.filerquest != "undefined") {
          this.filter.question = Number(this.routeParams.filerquest);
        } else {
          this.filter.question = "";
        }
        if (typeof this.routeParams.size != "undefined") {
          this.activeName = this.routeParams.size;
        } else {
          this.activeName = "s";
        }
      }
    },
    fetchAnswer() {
      this.vLoading = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let path = "";
      if (this.type == "answer") {
        path = "choices";
      } else {
        path = "recommend";
      }
      HTTP.get(path)
        .then(res => {
          if (res.data.success) {
            this.vLoading = false;
            this.resultData = res.data.obj;
          } else {
            this.alertFailError();
          }
        })
        .catch(e => {
          this.checkAuth();
          console.log("createAnswer error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.vLoading = false;
          }, 2000);
          this.loading = false;
        });
    },

    exportAnswer ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`export/assessment`)
        .then(res => {
          // console.log(res);
          if (res.data.success) {
            var name = "_blank";
            var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];

            const url = process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.obj;
            //const win = window.open(url, specs);
            var createA = document.createElement("a");
            createA.setAttribute("href", url);
            createA.setAttribute("target", "_blank");
            createA.click();

            this.$message({
              type: "success",
              message: "ส่งออกข้อมูลสำเร็จ"
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    exportRecommend ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`export/recommend`)
        .then(res => {
          // console.log(res);
          if (res.data.success) {
            var name = "_blank";
            var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];

            const url = process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.obj;
            //const win = window.open(url, specs);
            var createA = document.createElement("a");
            createA.setAttribute("href", url);
            createA.setAttribute("target", "_blank");
            createA.click();
            this.$message({
              type: "success",
              message: "ส่งออกข้อมูลสำเร็จ"
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
